import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,{staticClass:"mx-auto pa-3",staticStyle:{"margin-top":"5vh"},attrs:{"max-width":"500"}},[_c(VCardTitle,[_vm._v(" Create new password ")]),_c(VContainer,[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit()}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[(_vm.error)?_c(VAlert,{attrs:{"type":"error","text":""}},[_vm._v(_vm._s(_vm.error))]):_vm._e(),(_vm.success)?_c(VAlert,{attrs:{"type":"success","success":""}},[_vm._v(_vm._s(_vm.success))]):_vm._e(),(!_vm.success)?_c('div',[_c('password-field',{attrs:{"label":"Password","rules":_vm.rules.passwordRule,"show-password":""},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('password-field',{attrs:{"rules":_vm.confirmPasswordRule,"show-password":"","label":"Confirm Password"},model:{value:(_vm.confirmPassword),callback:function ($$v) {_vm.confirmPassword=$$v},expression:"confirmPassword"}})],1):_vm._e(),(_vm.success)?_c('div',{staticClass:"text-center"},[_c(VAlert,{attrs:{"color":"info","text":""}},[_vm._v(" You can now continue to Login page and use your new password ")]),_c(VBtn,{staticClass:"text-capitalize",attrs:{"color":"primary","large":"","to":"/login"}},[_vm._v("Login")])],1):_c('div',[_c(VBtn,{staticClass:"text-capitalize",attrs:{"block":"","color":"primary","loading":_vm.loading,"large":""},on:{"click":function($event){return _vm.submit()}}},[_vm._v("Save new password")]),_c('div',{staticClass:"mt-2"},[_c('small',{staticClass:"grey--text"},[_vm._v(" Click the button above to change your password to a new password ")])])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }