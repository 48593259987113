<template>
  <v-card max-width="500" style="margin-top: 5vh" class="mx-auto pa-3">
    <v-card-title> Create new password </v-card-title>
    <v-container>
      <v-form ref="form" v-model="valid" @submit.prevent="submit()">
        <v-alert type="error" text v-if="error">{{ error }}</v-alert>
        <v-alert type="success" success v-if="success">{{ success }}</v-alert>

        <div v-if="!success">
          <password-field
            label="Password"
            v-model="password"
            :rules="rules.passwordRule"
            show-password
          ></password-field>
          <password-field
            v-model="confirmPassword"
            :rules="confirmPasswordRule"
            show-password
            label="Confirm Password"
          ></password-field>
        </div>
        <div class="text-center" v-if="success">
          <v-alert color="info" text>
            You can now continue to Login page and use your new password
          </v-alert>
          <v-btn color="primary" class="text-capitalize" large to="/login">Login</v-btn>
        </div>
        <div v-else>
          <v-btn
            block
            class="text-capitalize"
            color="primary"
            :loading="loading"
            @click="submit()"
            large
            >Save new password</v-btn
          >
          <div class="mt-2">
            <small class="grey--text">
              Click the button above to change your password to a new password
            </small>
          </div>
        </div>
      </v-form>
    </v-container>
  </v-card>
</template>

<script>
import firebase from 'firebase/app';

import rules from '@/mixins/rules';
import 'firebase/auth';
export default {
  mixins: [rules],
  data: () => ({
    password: '',
    confirmPassword: '',
    success: null,
    error: null,
    loading: false,
    valid: true
  }),
  components: {
    PasswordField: () => import('../components/Core/PasswordField.vue')
  },
  computed: {
    confirmPasswordRule() {
      return [
        v => !!v || 'Confirm Password is required',
        v => v === this.password || 'The password confirmation does not match.'
      ];
    }
  },
  methods: {
    submit() {
      this.error = null;
      this.success = null;
      this.$refs.form.validate();
      if (this.valid) {
        this.resetPassword();
      }
    },
    async resetPassword() {
      let code = this.$route.query.oobCode;
      this.loading = true;

      await firebase
        .auth()
        .verifyPasswordResetCode(code)
        .then(async () => {
          await firebase
            .auth()
            .confirmPasswordReset(code, this.password)
            .then(() => {
              this.success = 'Your password has been successfully changed';
              this.$refs.form.reset();
            });
        })
        .catch(err => {
          if (err.message) {
            this.error = err.message;
          } else {
            this.error = 'Invalid password reset link';
          }
        });
      this.loading = false;
    }
  }
};
</script>

<style></style>
