<template>
  <v-main>
    <v-container>
      <v-card class="mx-auto relative" max-width="800">
        <v-btn to="/parcels" class="icon-close" fab x-small>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <PostDetail :id="id" v-if="id" :gallery-ref="galleryRef" :editable="false" />
      </v-card>
    </v-container>
  </v-main>
</template>

<script>
import Gallery from '../services/gallery';
const g = new Gallery();
import PostDetail from '../components/Gallery/PostDetail.vue';
export default {
  components: {
    PostDetail
  },
  data() {
    return {
      id: '',
      detail: null,
      galleryRef: g.ref
    };
  },
  mounted() {
    this.fetch();
  },
  methods: {
    fetch() {
      const imageId = String(this.$route.hash || '').replace('#', '');
      g.get({ imageId })
        .then(gallery => {
          this.id = gallery.id;
          this.detail = gallery.data;
        })
        .catch(err => {
          console.warn(err);
          this.$router.push('/parcels');
        });
    }
  }
};
</script>

<style scoped>
.icon-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 5;
  margin-right: 5px;
  margin-top: 5px;
}
</style>